import ScrollAnimation from "react-animate-on-scroll"

interface CardProps {
    title: string;
    description: string;
    technologies: string[];
  }
  
  export const Card: React.FC<CardProps> = ({ title, description, technologies }) => {
    return (
      <ScrollAnimation animateIn="flipInX">
        <div className="project">
          <header>
            <svg
              width="50"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#23ce6b"
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <title>Folder</title>
              <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
            </svg>
          </header>
          <div className="body">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          <footer>
            <ul className="tech-list">
              {technologies.map((tech, index) => (
                <li key={index}>{tech}</li>
              ))}
            </ul>
          </footer>
        </div>
      </ScrollAnimation>
    );
  };